<template>
    <div class="p-2">
        <b-breadcrumb class="mb-2" :items="breadcrumbs"></b-breadcrumb>
        <div class="row no-gutters">
            <div class="col-12">
                <paginated-result-loader endpoint="additional-documents" cell-type="additional_documents"
                                         :filters="request"></paginated-result-loader>
            </div>

        </div>
    </div>
</template>

<script>
    import PaginatedResultLoader from "../../../../components/PaginatedResultLoader";

    export default {
        name: "additional_documents",
        components: {PaginatedResultLoader},
        data: function () {
            return {
                request: {},
                paginatedResponse: {},
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Additional document types',
                        active: true
                    }
                ]
            }
        },
        methods: {

        }

    }
</script>